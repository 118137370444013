<template>
  <div>
    <div class="mySocial_list">
      <div class="socialInfo_content">
        <div class="socialInfo_left">参保人：</div>
        <div>{{ person_name }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">身份证号：</div>
        <div>{{ person_card_code }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">参保城市：</div>
        <div>{{ city_id }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">参保月：</div>
        <div>{{ month }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">参保类型：</div>
        <div>{{ socialtype }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">养老保险：</div>
        <div>{{ endowment }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">医疗保险：</div>
        <div>{{ medical }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">失业保险：</div>
        <div>{{ unemployment }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">工伤保险：</div>
        <div>{{ employmentInjury }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">生育保险：</div>
        <div>{{ maternity }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">公积金：</div>
        <div>{{ accumulationFund }}</div>
      </div>
    </div>
  </div>
</template>
<script>
// import { formatMinute } from "../utils/tool";
export default {
  data() {
    return {
      id: "",
      person_name: "啊哈哈", //参保人
      person_card_code: "", //身份证号
      city_id: "", //参保城市
      month: "", //参保月
      socialtype: "", //参保类型
      endowment: "", //养老保险
      medical: "", //医疗保险
      unemployment: "", //失业保险
      employmentInjury: "", //工伤保险
      maternity: "", //生育保险
      accumulationFund: "", //公积金
      socialtypeList: [],
      infoId:"",
    };
  },
  mounted() {
    let pid = localStorage.getItem("pid");
    let id = localStorage.getItem("id");
    this.pid = pid;
    // 测试
    // this.pid=53738;
    this.id = id;
    this.infoId=this.$route.params.id;
  },
  methods: {
    
  },
};
</script>
<style scoped>

.mySocial_list {
  border: 0.0625rem solid #ddd;
  width: calc(100% - 1.875rem);
  margin: 0 auto;
  margin-top: 0.625rem;
  border-radius: 0.9375rem;
  padding: 0.3125rem 0.9375rem 1.875rem 0.9375rem;
  box-sizing: border-box;
  font-size: 0.9375rem;
  box-shadow: 0.3125rem 0.3125rem 0.3125rem rgb(0 0 0 / 18%);
}

.socialInfo_content {
  border-bottom: 0.0625rem solid #ddd;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  line-height: 2.1875rem;
}
.socialInfo_left{
  width:5rem;
}
</style>

